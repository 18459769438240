import React, { useState } from "react";
import Jon from "../../../images/jon.png";
import { UserAvatar } from "../../Component";
import { getInitials } from "../../../utils/Utils";
const RequestorDetails = ({ name, email, title, company, avatar, website }) => {
    //   const [inputCheck, setCheck] = useState(checked ? true : false);

    return (
        <React.Fragment>
            <div clastyle={{ width: "10%" }} className="project-head text-start">
                <div className="user-card" style={{ maxWidth: 'fit-content' }}>
                    {/* <div id="at_avatar" className="user-avatar" style={{ width: '60px', height: '60px' }}>
                        <img id="at-avatar-top-name" src={avatar} alt="" />
                    </div> */}
                    <UserAvatar imageLink={avatar} className="md" text={getInitials(name)}></UserAvatar>
                    {/* The bottom check is displayed when on complete page */}
                    <div id="at-check" className="user-avatar" style={{ display: 'none', backgroundColor: '#1DB954', width: '50px', height: '50px' }}>
                        <span>
                            <i className="mt-1 fa fa-check" style={{ fontSize: '20px', color: 'white' }}></i>
                        </span>
                    </div>
                    <div className=" ms-2 text-start">
                        <strong id="req_name">{name}</strong>
                        <a className="ps-1" id="email" href={"mailto://" + email} target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" title={email} rel="noreferrer">
                            <em className="icon ni ni-mail"></em>
                        </a><br></br>

                        <span className="sub-text"> {title}</span>
                        <span className="sub-text"> {company}</span>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

export default RequestorDetails;


