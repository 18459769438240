import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { Block, BlockAthenty, Button, Icon, Input } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import Head from "../../../layout/head/Head";
import { Link } from "react-router-dom";
import LogoDark from "../../../images/logo-dark.png";
import WheelPicker from '@maksim-tolo/react-wheel-picker';



const Sms = () => {

  const [selectedItem, setSelectedItem] = useState(

  );
  const [isWheelVisible, setIsWheelVisible] = useState(false);
  const [tempSelectedItem, setTempSelectedItem] = useState(selectedItem);
  const data = [
    {
      id: '1',
      value: 'canada'
    },
    {
      id: '2',
      value: 'test2'
    },
    {
      id: '3',
      value: 'test3'
    },
    {
      id: '4',
      value: 'test4'
    },
    {
      id: '6',
      value: 'test5'
    },
    {
      id: '7',
      value: 'test5'
    },
    {
      id: '8',
      value: 'test5'
    },
    {
      id: '9',
      value: 'test5'
    },
    {
      id: '10',
      value: 'test5'
    },
    {
      id: '11',
      value: 'test5'
    },
    {
      id: '12',
      value: 'test5'
    }

  ];
  const handleSelect = () => {
    setSelectedItem(tempSelectedItem);  // Confirm the selected item
    console.log(tempSelectedItem)
    setIsWheelVisible(false);  // Hide the wheel picker
  };

  const handleCancel = () => {
    setTempSelectedItem(selectedItem);  // Reset to the previously selected item
    setIsWheelVisible(false);  // Hide the wheel picker
  };

  const handleOpenPicker = () => {
    setIsWheelVisible(true);  // Show the wheel picker
  };

  const handleOnChange = target => {
    console.log(target)
    setTempSelectedItem(target)
    console.log(target);
  };

  return (
    <div style={{ width: 200, margin: '0 auto' }}>
      {/* Dropdown Field *
      /}
      <div
        style={{
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '5px',
          cursor: 'pointer',
          textAlign: 'center',
        }}
        onClick={handleOpenPicker}
      >
        (Tap to Select)
      </div>

      {/* Modal with Wheel Picker */}
      {isWheelVisible && (
        <div style={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            textAlign: 'center',
          }}>
            {/* <h3>Select an Option</h3> */}
            <WheelPicker
              data={data}
              onChange={handleOnChange}
              height={150}
              width={100}
              titleText="Enter value same as aria-label"
              itemHeight={30}
              selectedID={data[0].id}
              color="#ccc"
              activeColor="#333"
              backgroundColor="#fff"
            />
            {/* Cancel and Select Buttons */}
            <div style={{ marginTop: '20px' }}>
              <button onClick={handleCancel} style={{ marginRight: '10px' }}>Cancel</button>
              <button onClick={handleSelect}>Select</button>
            </div>
            {selectedItem && <div>{selectedItem}</div>}
          </div>
        </div>
      )}
    </div>
  );
}

export default Sms;
