import React, { useState } from "react";
import Content from "../../../../../../layout/content/Content";
import { Icon, Button, TooltipComponent } from "../../../../../../components/Component";
import GalleryImage from "../../../../../../components/partials/gallery/GalleryImage";
import { Badge, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from "reactstrap";
import countryList from "react-select-country-list";
import { capitalizeWords } from "../../../../../../utils/Utils";

const VerifyDocuments = ({ data }) => {
  const [documentsData] = useState(data.documents);
  console.log(documentsData)
  const [isOpen1, setIsOpen1] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [tooltipOpen1, setOpen1] = useState(false);
  const [tooltipOpen2, setOpen2] = useState(false);
  const ordinalMap = {
    1: 'First',
    2: 'Second',
    3: 'Third',
    4: 'Fourth',
    5: 'Fifth',
    6: 'Sixth',
    7: 'Seventh',
    8: 'Eighth',
    9: 'Ninth',
    10: 'Tenth'
    // Add more mappings as needed
  };
  const toggle1 = () => {
    console.log('1')
    setIsOpen1(!isOpen1);
    setModal1(!modal1);
  };

  const toggle2 = () => {
    setIsOpen2(!isOpen2);
    setModal2(!modal2);
    console.log('2')
  };

  const toggleTooltip1 = () => {
    setOpen1(!tooltipOpen1);
  };

  const toggleTooltip2 = () => {
    setOpen2(!tooltipOpen2);
  };

  const getResult = (search, results) => {
    for (let i = 0; i < results.length; i++) {
      if (search.Alias === Object.keys(results[i])[0]) {
        return results[i][search.Alias];
      }
    }
    return "No result";
  };

  const findValueOfNumber = (dataArray, keyToFind) => {
    const dynamicKey =
      keyToFind === "passport"
        ? "PASSPORT_NUMBER"
        : keyToFind === "card"
          ? "ID_NUMBER"
          : keyToFind === "drivers"
            ? "DRIVERS_NUMBER"
            : "CREDIT_NUMBER";

    const value = dataArray.find((obj) => obj.hasOwnProperty(dynamicKey));
    if (!value) return "Not Found";
    return value[dynamicKey];
  };

  return (
    <React.Fragment>
      <Content>
        {!documentsData && (
          <div className="nk-block-head">
            <div className="card card-bordered card-stretch">
              <h5 className="title" style={{ textAlign: "center", margin: "10px" }}>
                No Documents Collected
              </h5>
            </div>
          </div>
        )}
        {documentsData && documentsData.map((document, index) => {
          return (
            <React.Fragment key={index}>
              <div className="nk-block-head">
                <h5 className="title">
                  {ordinalMap[index + 1]}
                  <span style={{ textAlign: "right", position: "absolute", right: "0px" }}>
                    {(data.status === "complete" || data.status === 'requires_review') && (
                      <>
                        {" "}
                        <Button
                          color={
                            document.score > 95
                              ? "success"
                              : document.score > 50
                                ? "warning"
                                : document.score >= 0
                                  ? "danger"
                                  : "gray"
                          }
                          className="btn-xs"
                          onClick={index === 0 ? toggle1 : toggle2}
                          id="remark-1"
                        >
                          <span>Score : {document.score >= 0 ? document.score : "Not calculated"}</span>{" "}
                          {
                            <Icon
                              name={
                                document.score > 75
                                  ? "check"
                                  : document.score > 50
                                    ? "alert"
                                    : document.score >= 0
                                      ? "cross"
                                      : ""
                              }
                            ></Icon>
                          }
                        </Button>
                        <Tooltip
                          trigger="hover"
                          placement="top"
                          isOpen={tooltipOpen1}
                          target="remark-1"
                          toggle={index === 0 ? toggleTooltip1 : toggleTooltip2}
                        >
                          View Intelligence Results
                        </Tooltip>
                      </>
                    )}
                    {console.log(data.status !== "complete")}
                    {(data.status !== "complete" && data.status !== 'requires_review') && (
                      <>
                        {" "}
                        <Button color="gray" className="btn-xs" onClick={index === 0 ? toggle1 : toggle2} id="remark-1">
                          <span>Score : Not calculated</span>
                        </Button>
                        <Tooltip
                          trigger="hover"
                          placement="top"
                          isOpen={tooltipOpen1}
                          target="remark-1"
                          toggle={index === 0 ? toggleTooltip1 : toggleTooltip2}
                        >
                          Will be available after request completed
                        </Tooltip>
                      </>
                    )}
                  </span>
                  <Modal isOpen={index === 0 ? modal1 : modal2} toggle={index === 0 ? toggle1 : toggle2}>
                    <ModalHeader
                      toggle={index === 0 ? toggle1 : toggle2}
                      close={
                        <button className="close" onClick={index === 0 ? toggle1 : toggle2}>
                          <Icon name="cross" />
                        </button>
                      }
                    >
                      Document 1 Intelligence Results
                    </ModalHeader>
                    <ModalBody>

                      <>
                        {document.file1_ocr_results && (
                          <div className="card card-bordered card-stretch">
                            {/* <div style={{ display: "flex", justifyContent: "center", margin: "5px" }}>
                          <img src={data.documents[0].file1_s3url} alt="document 1" style={{ width: "50%" }} />
                        </div> */}
                            {/* <hr style={{ margin: "0" }} /> */}
                            <div className="card-inner-group">
                              <div className="card-inner p-0">
                                <div className="nk-tb-list nk-tb-ulist">
                                  <div className="profile-ud wider smaller">
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        margin: "0px 0px 0px 20px",
                                        width: "50%",
                                      }}
                                      className="profile-ud-label"
                                    >
                                      Flags: <br />
                                    </span>
                                  </div>

                                  {document.flags.length ? (
                                    document.flags.map((flag, index) => {
                                      return (
                                        <div key={index} className="profile-ud wider smaller">
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              margin: "0px 0px 0px 20px",
                                              width: "100%",
                                            }}
                                            className="profile-ud-label text-danger fw-bold"
                                          >
                                            {flag}
                                          </span>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div className="profile-ud wider smaller">
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          margin: "0px 0px 0px 20px",
                                          width: "100%",
                                        }}
                                        className="profile-ud-label fw-bold"
                                      >
                                        No Flag
                                      </span>
                                    </div>
                                  )}

                                  <hr style={{ margin: "0" }} />
                                  {document.file1_ocr_results.map((obj, objIndex) =>
                                    Object.entries(obj).map(([key, value], index) => (
                                      <React.Fragment key={index}>
                                        <div className="profile-ud wider smaller">
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              margin: "0px 0px 0px 20px",
                                              width: "50%",
                                            }}
                                            className="profile-ud-label"
                                          >
                                            {key !== "MRZ_CODE" && key.replace(/_/g, " ")}
                                          </span>

                                          <span
                                            style={{
                                              textAlign: "left",
                                              fontSize: "14px",
                                              margin: "0px 20px 0px 0px",
                                              width: "50%",
                                            }}
                                            id="mobile-number"
                                            className="profile-ud-value"
                                          >
                                            {key !== "MRZ_CODE" && (value || "Not Found")}
                                          </span>
                                        </div>
                                        {index !== Object.entries(obj).length - 1 && <hr style={{ margin: "0" }} />}
                                      </React.Fragment>
                                    )),
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {document.file2_ocr_search && (
                          <div className="card card-bordered card-stretch mt-2">
                            {/* <div style={{ display: "flex", justifyContent: "center", margin: "5px" }}>
                          <img src={data.documents[0].file2_s3url} alt="document 2" style={{ width: "50%" }} />
                        </div> */}
                            <div className="card-inner-group">
                              <div className="card-inner p-0">
                                <div className="nk-tb-list nk-tb-ulist">
                                  {document.file2_ocr_search.map((x, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {/* <hr style={{ margin: "0" }} /> */}
                                        <div className="profile-ud wider smaller">
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              margin: "0px 0px 0px 20px",
                                              width: "50%",
                                            }}
                                            className="profile-ud-label"
                                          >
                                            {x.Text}
                                          </span>

                                          <span
                                            style={{
                                              textAlign: "left",
                                              fontSize: "14px",
                                              margin: "0px 20px 0px 0px",
                                              width: "50%",
                                            }}
                                            id="mobile-number"
                                            className="profile-ud-value"
                                          >
                                            {getResult(x, document.file2_ocr_results)}
                                          </span>
                                        </div>
                                        {x !==
                                          document.file2_ocr_search[
                                          document.file2_ocr_search.length - 1
                                          ] && <hr style={{ margin: "0" }} />}
                                      </React.Fragment>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {document.file3_ocr_search && (
                          <div className="card card-bordered card-stretch mt-2">
                            {/* <div style={{ display: "flex", justifyContent: "center", margin: "5px" }}>
                          <img src={data.documents[0].file3_url} alt="document 2" style={{ width: "50%" }} />
                        </div> */}
                            <div className="card-inner-group">
                              <div className="card-inner p-0">
                                <div className="nk-tb-list nk-tb-ulist">
                                  {document.file3_ocr_search.map((x, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {/* <hr style={{ margin: "0" }} /> */}
                                        <div className="profile-ud wider smaller">
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              margin: "0px 0px 0px 20px",
                                              width: "50%",
                                            }}
                                            className="profile-ud-label"
                                          >
                                            {x.Text}
                                          </span>

                                          <span
                                            style={{
                                              textAlign: "left",
                                              fontSize: "14px",
                                              margin: "0px 20px 0px 0px",
                                              width: "50%",
                                            }}
                                            id="mobile-number"
                                            className="profile-ud-value"
                                          >
                                            {getResult(x, document.file3_ocr_results)}
                                          </span>
                                        </div>
                                        {x !==
                                          document.file3_ocr_search[
                                          document.file3_ocr_search.length - 1
                                          ] && <hr style={{ margin: "0" }} />}
                                      </React.Fragment>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>

                    </ModalBody>
                  </Modal>
                </h5>
              </div>
              <div className="nk-block">
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    <div className="card-inner p-0">
                      <div className="nk-tb-list nk-tb-ulist">
                        <div className="profile-ud wider">
                          <span
                            style={{
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "25%",
                            }}
                            className="profile-ud-label"
                          >
                            Type
                          </span>

                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 20px 0px 0px",
                              width: "25%",
                            }}
                            id="type"
                            className="profile-ud-value"
                          >
                            {document.type
                              ? document.type === "card"
                                ? "Photo ID"
                                : document.type === "credit"
                                  ? "Credit card"
                                  : document.type === "passport"
                                    ? "Passport"
                                    : document.type === "drivers"
                                      ? "Driver's Licence"
                                      : ""
                              : ""}
                          </span>
                          <span
                            style={{
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "25%",
                            }}
                            className="profile-ud-label"
                          >
                            As of
                          </span>

                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 20px 0px 0px",
                              width: "25%",
                            }}
                            id="as-of"
                            className="profile-ud-value"
                          >
                            {document.created_at
                              ? document.created_at.substring(0, 10)
                              : ""}
                          </span>
                        </div>

                        <hr style={{ margin: "0" }} />
                        <div className="profile-ud wider">
                          <span
                            style={{
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "25%",
                            }}
                            className="profile-ud-label"
                          >
                            Issued by
                          </span>

                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 20px 0px 0px",
                              width: "75%",
                            }}
                            id="issued-by"
                            className="profile-ud-value"
                          >{document.file1_ocr_results.find((obj) => obj.hasOwnProperty("ISSUING_COUNTRY")) ? document.file1_ocr_results.find((obj) => obj.hasOwnProperty("ISSUING_COUNTRY")).ISSUING_COUNTRY : 'Not Found'}</span>
                        </div>

                        <hr style={{ margin: "0" }} />
                        {document.type !== 'credit' && <div className="profile-ud wider">
                          <span
                            style={{
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "25%",
                            }}
                            className="profile-ud-label"
                          >
                            Country of Issue
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 20px 0px 0px",
                              width: "25%",
                            }}
                            id="country-of-issue"
                            className="profile-ud-value"
                          >
                            {document.issue_country
                              ? countryList().getLabel(document.issue_country)
                              : ""}
                          </span>

                          {/* <span
                            style={{
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "25%",
                            }}
                            className="profile-ud-label"
                          >
                            Authentication
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 20px 0px 0px",
                              width: "25%",
                            }}
                            id="authentication"
                            className="profile-ud-value"
                          ></span> */}
                          <span
                            style={{
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "25%",
                            }}
                            className="profile-ud-label"
                          >
                            State/Province
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 20px 0px 0px",
                              width: "25%",
                            }}
                            id="region"
                            className="profile-ud-value"
                          >
                            {document.issue_region ? document.issue_region : "---"}
                          </span>
                        </div>}
                        {document.type !== 'credit' && <hr style={{ margin: "0" }} />}
                        <div className="profile-ud wider">
                          <span
                            style={{
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                              width: "25%",
                            }}
                            className="profile-ud-label"
                          >
                            {document.type === "credit"
                              ? "Name On Card"
                              : capitalizeWords(document.type) + " Number"}
                          </span>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 20px 0px 0px",
                              width: "75%",
                            }}
                            id="number"
                            className="profile-ud-value"
                          >
                            {document.type === "credit"
                              ? document.file1_ocr_results.find((obj) => obj.hasOwnProperty("CREDIT_NAME"))
                                .CREDIT_NAME
                              : findValueOfNumber(document.file1_ocr_results, document.type)}
                          </span>
                        </div>

                        <hr style={{ margin: "0" }} />
                        <div className="profile-ud wider">

                          <span
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                            }}
                          >
                            {document.file1_s3url && (
                              <>
                                {document.type === 'credit' && <div>Credit card front</div>}
                                {/* <GalleryImage img={document.file1_s3url} height="250px" /> */}
                                {document.file1_s3url === 'privacy' ?
                                  <div class="border d-flex align-items-center justify-content-center" style={{ height: "200px", backgroundColor: "black" }}>

                                    <div class="card-body">
                                      <p class="card-text">For privacy reasons, this document cannot be shown</p>
                                    </div>
                                  </div>
                                  :
                                  <GalleryImage img={document.file1_s3url} height="250px" />
                                }
                              </>
                            )}
                          </span>
                          <span
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                            }}
                          >
                            {document.type === 'credit' && <div>Credit card back</div>}
                            {/* {document.file2_s3url && (
                              < GalleryImage img={document.file2_s3url} height="250px" />
                            )} */}
                            {document.file2_s3url === 'privacy' && document.type === 'credit' ?
                              <div class="border d-flex align-items-center justify-content-center" style={{ height: "200px", backgroundColor: "black" }}>

                                <div class="card-body">
                                  <p class="card-text">For privacy reasons, this document cannot be shown</p>
                                </div>
                              </div>
                              :
                              <GalleryImage img={document.file2_s3url} height="250px" />
                            }
                          </span>
                          <span
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              margin: "0px 20px 0px 20px",
                            }}
                          >
                            {document.type === 'credit' && <div>Bill</div>}
                            {/* {document.file3_s3url && (
                              <GalleryImage img={document.file3_s3url} height="250px" />
                            )} */}
                            {document.file3_s3url === 'privacy' && document.type === 'credit' &&
                              <div class="border d-flex align-items-center justify-content-center" style={{ height: "200px", backgroundColor: "black" }}>

                                <div class="card-body">
                                  <p class="card-text">For privacy reasons, this document cannot be shown</p>
                                </div>
                              </div>
                            }

                            {document.type === 'credit' && <GalleryImage img={document.file3_s3url} height="250px" />}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
        })}
      </Content>
    </React.Fragment>
  );
};

export default VerifyDocuments;
