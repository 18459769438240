import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";

import { Block, Button, DocumentScan, FaceScanner, Icon, Loading } from "../../../components/Component";
import { useForm } from "react-hook-form";
import { apiRequest } from "../../../utils/Api";
import { useLocation } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { LuEqual, LuEqualNot } from "react-icons/lu";
import { Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const FaceScan = ({ ...props }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const request_id = queryParameters.get("request_id");
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(JSON.parse(localStorage.getItem("at_requestor")));
  const [modalData, setModalData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const [facialDetail, setFacialDetail] = useState();
  const [faceMatches, setFaceMatches] = useState();
  const [unmatchedFaces, setUnmatchedFaces] = useState();
  const [croppedCompareImageBase64, setCroppedCompareImageBase64] = useState();
  const [croppedImageBase64, setCroppedImageBase64] = useState();
  const [data, setData] = useState();
  const location = useLocation();
  const id = location.search.split("=")[1];

  const [load, setLoad] = useState(0);
  if (load < 1) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoad(2);
  }

  useEffect(() => {
    setRequest(request);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getUserInfo();
  }, []);

  const closeModal = () => {
    props.jump(modalData.step);
    setModalOpen(false);
  };

  const getUserInfo = async () => {
    await apiRequest("/ccr/verify/" + request_id, "GET").then((resp) => {
      console.log(resp);
      if (resp.code === 200) {
        setData(resp.data.request);
        setValue("from_name", resp.data.request.from_name);
        setValue("from_company", resp.data.request.from_company);
        console.log(data);
        setLoading(false);
        // onCompare()
      }
    });
  };

  const locationAccess = async () => {
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true, // Request high accuracy (GPS)
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0, // Ensure the result is not a cached position
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setValue("location", { latitude, longitude });
          resolve({ latitude, longitude });
        },
        (err) => {
          // Handle error
          console.log(err);
          resolve({ latitude: null, longitude: null });
        },
        options,
      );
    });
  };

  const onFormSubmit = async (e) => {
    setSubmitButtonLoading(true);
    var data = new FormData();
    data.append("biometric_face", getValues("face_scan")[0]);
    const location = await locationAccess();
    data.append("latitude", location.latitude || null);
    data.append("longitude", location.longitude || null);
    try {
      await apiRequest(`/ccr/verify/step/6`, "POST", data, "file").then((resp) => {
        resp = JSON.parse(resp);
        console.log(resp);
        if (resp.code === 200) {
          props.jump(resp.data.step);
        } else {
          setSubmitButtonLoading(false);
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
    } catch (err) {
      console.log(err);
      setSubmitButtonLoading(false);
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // console.log('submit');

    await handleSubmit(onFormSubmit)();

    if (Object.keys(errors).length) {
      toast.error('missing information', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <React.Fragment>
      <Head title="Face Scan" />
      {/* <HeaderCCR></HeaderCCR> */}
      <div className="mb-2"></div>
      {loading && !data && <Loading />}
      {!loading && data && (
        <Content>
          <div className="content-page wide-md m-auto">
            <Block className={submitButtonLoading && "disabled"}>
              <h5>Face Scan</h5>
              <p>Please take a selfie.</p>

              <form className="content clearfix" onSubmit={handleFormSubmit}>
                <div className="gy-4 row">
                  <div className="entry">
                    <h6>To avoid delays in verifying you, please follow the instructions below:</h6>
                    <ul className="list list-sm list-checked">
                      <li>You are the only person or object in the picture.</li>
                      <li>
                        You take the picture directly in front of your face, you include all of your face and not on an
                        angle.
                      </li>
                    </ul>
                    <FaceScanner
                      buttonText="Scan Face"
                      setValue={setValue}
                      value={watch("face_scan")}
                      requiredMessage="This field is required"
                      required
                      errors={errors}
                      capture="user"
                      id="1"
                      register={register}
                      name="face_scan"
                    />
                    <div className="mt-3 nk-kycfm-note">
                      <em className=" icon ni ni-info-fill"></em>
                      {/* <p>
                      Your image is encrypted, analyzed, authenticated and only
                      used to verify you. It is only shared withand his
                      organization, who alone control access under encryption.
                      The image is stored securely at our CA1 Region Datacenter
                      [Toronto, Canada].
                    </p> */}
                      <p>
                        Your image is encrypted, analyzed, authenticated and only used to verify you by{" "}
                        <b>{data.from_name}</b> and <b>{data.from_company}</b>.
                      </p>
                    </div>

                    <div className="bg-primary" style={{ position: "fixed", bottom: "0px" }}>
                      <div
                        className="bg-white col-12"
                        style={{
                          position: "fixed",
                          bottom: "0px",
                          right: "0px",
                        }}
                      >
                        <div
                          style={{
                            margin: "10px 5px 10px 0px",
                            float: "right",
                          }}
                          className="p-1"
                        >
                          {" "}
                          <Button color="primary" size="" className="" outline="">
                            {submitButtonLoading && <Spinner size="sm" />} Continue
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="actions clearfix">
                    <ul>
                      <li>
                        <button type="submit" style={{float:'right'}} className="btn btn-primary">Next</button>
                      </li>
                    </ul>
                  </div> */}
              </form>
            </Block>
          </div>
        </Content>
      )}
      <Modal isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader
          toggle={closeModal}
          close={
            <button className="close" onClick={closeModal}>
              <Icon name="cross" />
            </button>
          }
        >
          OCR RESULTS
        </ModalHeader>
        <ModalBody>
          {modalData && modalData.data[0].biometric_face.image1_facial_s3 && watch("face_scan") && (
            <>
              <h5>Image 1</h5>
              <DocumentScan capture="user" showButtun={false} value={watch("face_scan")} />

              {/* <p>{modalData.data[0].document.image1_ocr.queries[0].PASSPORT_COVER}</p> */}
            </>
          )}
          {modalData && modalData.data[0].biometric_face.image1_facial_s3 && watch("face_scan") && (
            <>
              <h5>Extracted face</h5>
              <div className="text-center">
                <img
                  height={80}
                  src={`data:image/png;base64, ${modalData.data[0].biometric_face.image1_facial_s3}`}
                  alt="Red dot"
                />
              </div>
            </>
          )}

          {modalData && modalData.data[0].biometric_face.image1_facial_s3 && watch("face_scan") && (
            <>
              <h5>extracted faces from documents</h5>
              <div className="text-center">
                <img
                  height={80}
                  src={`data:image/png;base64, ${modalData.data[0].biometric_face.image1_document_facial_s3_0}`}
                  alt="Red dot"
                />
                <img
                  height={80}
                  src={`data:image/png;base64, ${modalData.data[0].biometric_face.image1_document_facial_s3_1}`}
                  alt="Red dot"
                />
              </div>
            </>
          )}

          {modalData && modalData.data[0].biometric_face.image1_facial_s3 && watch("face_scan") && (
            <>
              <h5>Compare</h5>
              {modalData.data[0].biometric_face.compare_0 && (
                <Col>
                  <Row>
                    <Col>
                      {modalData.data[0].biometric_face.compare_0.croppedImageBase64 && (
                        <img
                          height={80}
                          src={`data:image/png;base64, ${modalData.data[0].biometric_face.image1_facial_s3}`}
                        />
                      )}
                    </Col>
                    <Col>
                      <Col>
                        {modalData.data[0].biometric_face.compare_0.unmatchedFaces && <LuEqualNot size={40} />}
                        {modalData.data[0].biometric_face.compare_0.faceMatches && <LuEqual size={40} />}
                      </Col>
                      <p>
                        Confidence:{" "}
                        {modalData.data[0].biometric_face.compare_0.faceMatches &&
                          modalData.data[0].biometric_face.compare_0.faceMatches.Similarity.toFixed(1)}{" "}
                        {modalData.data[0].biometric_face.compare_0.unmatchedFaces &&
                          modalData.data[0].biometric_face.compare_0.unmatchedFaces.Confidence.toFixed(1)}
                      </p>
                      {/* <p>Confidence: {faceMatches && faceMatches.Confidence.toFixed(1)}</p> */}
                    </Col>
                    <Col>
                      <img
                        height={80}
                        src={`data:image/png;base64, ${modalData.data[0].biometric_face.compare_0.croppedImageBase64}`}
                        alt="Red dot"
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              {modalData.data[0].biometric_face.compare_1 && (
                <Col>
                  <Row>
                    <Col>
                      {modalData.data[0].biometric_face.compare_1.croppedImageBase64 && (
                        <img
                          height={80}
                          src={`data:image/png;base64, ${modalData.data[0].biometric_face.image1_facial_s3}`}
                        />
                      )}
                    </Col>
                    <Col>
                      <Col>
                        {modalData.data[0].biometric_face.compare_1.unmatchedFaces && <LuEqualNot size={40} />}
                        {modalData.data[0].biometric_face.compare_1.faceMatches && <LuEqual size={40} />}
                      </Col>
                      <p>
                        Confidence:{" "}
                        {modalData.data[0].biometric_face.compare_1.faceMatches &&
                          modalData.data[0].biometric_face.compare_1.faceMatches.Similarity.toFixed(1)}{" "}
                        {modalData.data[0].biometric_face.compare_1.unmatchedFaces &&
                          modalData.data[0].biometric_face.compare_1.unmatchedFaces.Confidence.toFixed(1)}
                      </p>
                      {/* <p>Confidence: {faceMatches && faceMatches.Confidence.toFixed(1)}</p> */}
                    </Col>
                    <Col>
                      <img
                        height={80}
                        src={`data:image/png;base64, ${modalData.data[0].biometric_face.compare_1.croppedImageBase64}`}
                        alt="Red dot"
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </>
          )}
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  );
};

export default FaceScan;
