const req = { params: { id: "1547647f-5245-4f24-q123-a68386ed79d3" } };
export var ClientDetails = {
  request: req.params,
  client_name: "Jimmy Timmy Tim Tam",
  client_id: req.params.id,
  kyc: {
    updated: "January 12, 2023",
    last_verify: "23490823094239849023048092384923848",
  },
  profile: {
    title: "Mr.",
    name_first: "Jason",
    name_last: "Bourne",
    name_middle: "Charles",
    suffix: "Jr.",
    known_as: "JB",
    name_changed: "Yes",
    name_prev: "David Webb",
    pronounce: "He/Him",
    birthday: " 25th April 1974",
    sex: "Male",
    place_of_birth: "Nicosia, Cyprus",
    citizenship: " Wakanada",
    sin_ssn_tax: 123456789,
    maritial_status: "Single",
    occupation: "Analyst",
    income: "$250,000+",
    company: " Doofenshmirtz Evil Inc.",
    company_website: "www.doofenshmirtz.com",
    tax_residency: "Wakanda",
    legal_address: "7 Spyglass Hill Ballajura WA 6066",
    primary_redidence: " 7 Spyglass Hill Ballajura WA 6066",
    email: " mr_candy_man@gmail.com",
    phone: "123-456-7890",
  },
  verificationInfo: {
    submitted_by: "Jasone Bourne",
    submitted_on: "18 December 2019 01:02 PM",
    submitted_at: "7 Spyglass Hill Ballajura WA 6066",
    status: "approved",
    last_check_by: "Jeff Levy",
    last_check_on: " 18 December 2019 08:34 PM",
    digital_signature: "124567890",
    IPv4: "234.234.234.234",
    country: "Canada",
    region: "Ontario",
    city: "Toronto",
    postal: "H6D3M2",
    user_agent: "USER AGENT VALUE",
    geocoordinates: { Longitude: 32.234, Latitude: -54.234 },
  },

  activities: [
    {
      source: "15142828282",
      destination: "14165531541",
      ip: "136.49.84.151",
      date: "Tuesday, January 24th 2023, 6:37:42 pm EST",
      since: "4 months ago",
    },
    {
      source: "15142828282",
      destination: "14165531541",
      ip: "136.49.84.151",
      date: "Tuesday, January 24th 2023, 6:37:42 pm EST",
      since: "4 months ago",
    },
    {
      source: "15142828282",
      destination: "14165531541",
      ip: "136.49.84.151",
      date: "Tuesday, January 24th 2023, 6:37:42 pm EST",
      since: "4 months ago",
    },
    {
      source: "15142828282",
      destination: "14165531541",
      ip: "136.49.84.151",
      date: "Tuesday, January 24th 2023, 6:37:42 pm EST",
      since: "4 months ago",
    },
    {
      source: "15142828282",
      destination: "14165531541",
      ip: "136.49.84.151",
      date: "Tuesday, January 24th 2023, 6:37:42 pm EST",
      since: "4 months ago",
    },
    {
      source: "15142828282",
      destination: "14165531541",
      ip: "136.49.84.151",
      date: "Tuesday, January 24th 2023, 6:37:42 pm EST",
      since: "4 months ago",
    },
    {
      source: "15142828282",
      destination: "14165531541",
      ip: "136.49.84.151",
      date: "Tuesday, January 24th 2023, 6:37:42 pm EST",
      since: "4 months ago",
    },
  ],
  verifications: [
    {
      req_id: "23490823094239849023048092384923848",
      uid: req.params.id,
      type: "POI POA",
      status: "Received",
      date_requested: "Jun 8 5:23PM",
      date_completed: "Jun 8 6:58pm",
      check_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      check_name: "Jeff Levy",
      source: [
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      ],
    },
    {
      req_id: "1547647f-5245-4f24-a540-a68386ed79d3",
      uid: req.params.id,
      type: "POI POA",
      status: "Approved",
      date_requested: "Jun 8 5:23PM",
      date_completed: "Jun 8 6:58pm",
      check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      check_name: "Jeff Levy",
      source: [
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      ],
    },
    {
      req_id: "1547647f-5245-4f24-q123-a68386ed79d3",
      uid: req.params.id,
      type: "POI POA",
      status: "Approved",
      date_requested: "Jun 8 5:23PM",
      date_completed: "Jun 8 6:58pm",
      check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      check_name: "Jeff Levy",
      source: [
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      ],
    },
    {
      req_id: "1547647f-5245-4f24-r867-a68386ed79d3",
      uid: req.params.id,
      type: "POI POA",
      status: "Approved",
      date_requested: "Jun 8 5:23PM",
      date_completed: "Jun 8 6:58pm",
      check_avatar: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      check_name: "Jeff Levy",
      source: [
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      ],
    },
  ],
  documents: [
    {
      uid: req.params.id,
      req_id: "V001",
      doc_id: "doc-1",
      doctype: "Drivers Licence",
      region: "Toronto, Canada",
      verification_status: "pending",
      submitted: "Random Company",
      status: "Approved",
      added_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      added_name: "Jerald Hoffstead",
      check_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      check_name: "Jeff Levy",
      source: [
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      ],
    },

    {
      uid: req.params.id,
      req_id: "V002",
      doc_id: "doc-3",
      doctype: "Credit Card",
      region: "Canada",
      verification_status: "rejected",
      submitted: "Random Company",
      status: "Expired",
      added_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      added_name: "Herald Goffstead",
      check_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      check_name: "Jeff Levy",
      source: [
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      ],
    },

    {
      uid: req.params.id,
      req_id: req.params.id,
      doc_id: "doc-4",
      doctype: "Drivers Licence",
      region: "Toronto, Canada",
      verification_status: "pending",
      submitted: "Random Company",
      status: "Approved",
      added_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      added_name: "Jerald Hoffstead",
      check_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      check_name: "Jeff Levy",
      source: [
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      ],
    },

    {
      uid: req.params.id,
      req_id: "V002",
      doc_id: "doc-5",
      doctype: "Credit Card",
      region: "Canada",
      verification_status: "rejected",
      submitted: "Random Company",
      status: "Expired",
      added_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      added_name: "Herald Goffstead",
      check_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      check_name: "Jeff Levy",
      source: [
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      ],
    },
    {
      uid: req.params.id,
      req_id: req.params.id,
      doc_id: "doc-6",
      doctype: "Drivers Licence",
      region: "Toronto, Canada",
      verification_status: "pending",
      submitted: "Random Company",
      status: "Approved",
      added_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      added_name: "Jerald Hoffstead",
      check_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      check_name: "Jeff Levy",
      source: [
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      ],
    },

    {
      uid: req.params.id,
      req_id: "V003",
      doc_id: "doc-7",
      doctype: "Credit Card",
      region: "Canada",
      verification_status: "rejected",
      submitted: "Random Company",
      status: "Expired",
      added_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      added_name: "Herald Goffstead",
      check_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      check_name: "Jeff Levy",
      source: [
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
        "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg",
      ],
    },
  ],
  biometric: [
    {
      uid: req.params.id,
      req_id: "V001",
      bio_id: "bio-1",
      doctype: "Image",
      biometric: "Selfie",
      verification_status: "verified_ai",
      submitted: "Random Company",
      location: "Matched",
      status: "Approved",
      check_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      check_name: "Jeff Levy",
      prompt: "",
      source: ["https://img.freepik.com/free-photo/road-mountains-with-cloudy-sky_1340-23022.jpg"],
    },
    {
      uid: req.params.id,
      req_id: "V001",
      bio_id: "bio-2",
      doctype: "Audio",
      biometric: "Voice Recording",
      verification_status: "rejected",
      submitted: "Random Company",
      location: "Didn't Match",
      status: "Rejected",
      check_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      check_name: "Jeff Levy",
      prompt: "The cat ate the carrot and then scratched the couch",
      source: ["random.mp3"],
    },
    {
      uid: req.params.id,
      req_id: "V003",
      bio_id: "bio-3",
      doctype: "Audio",
      biometric: "Voice Recording",
      verification_status: "pending",
      submitted: "Random Company",
      location: "Didn't Match",
      status: "pending",
      check_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      check_name: "Jeff Levy",
      prompt: "The cat ate the carrot and then scratched the couch",
      source: ["random.mp3"],
    },
    {
      uid: req.params.id,
      req_id: "V003",
      bio_id: "bio-4",
      doctype: "Audio",
      biometric: "Voice Recording",
      verification_status: "pending",
      submitted: "Random Company",
      location: "Didn't Match",
      status: "pending",
      check_avatar: "https://static-00.iconduck.com/assets.00/link-square-icon-512x512-tgr53p19.png",
      check_name: "Jeff Levy",
      prompt: "The cat ate the carrot and then scratched the couch",
      source: ["random.mp3"],
    },
  ],
  notes: [
    {
      id: 1,
      title: "Profile",
      text: "Hello",
      date: "October 3, 2022 at 2:30 AM",
      by: "Carter Robinson",
    },
    {
      id: 2,
      title: "KYC",
      text: "hehe",
      date: "October 4, 2022 at 5:30 AM",
      by: "Carter Robinson",
    },
  ],
};
